import { IconSizeVariant, Palette, TextVariant } from 'tuix-webcomponents'

export const getFill = (
    saved: boolean,
    size?: IconSizeVariant,
    dark?: boolean,
): Palette | undefined | TextVariant => {
    if (size == IconSizeVariant.SMALL) {
        return saved ? TextVariant.MAIN : dark ? Palette.PRIMARY_800 : Palette.PRIMARY_300
    }
    return undefined
}

export const getColor = (
    saved: boolean,
    size?: IconSizeVariant,
    dark?: boolean,
): Palette | TextVariant => {
    const color = getFill(saved, size, dark)
    if (color != undefined) {
        return color
    }
    return saved ? Palette.WHITE : Palette.PRIMARY_300
}
