import React from 'react'

import { Locale } from 'utils/enums'
import BlogBanner from 'components/BlogBanner'

import { getDataByLocale, mappingNode } from '../../utils/dataHelpers'

import { usePageContent } from 'components/hooks/usePageContent/blog/usePageContent'
import ImageWithTextCard, {
    ImageWithTextCardModel,
} from 'components/ImageWithTextCard/ImageWithTextCard'
import Page from 'components/Page'
import Section, { SectionModel, TextAlign } from 'components/Section/Section'
import './index.scss'
import { MetaData } from 'components/Seo'

interface Props {
    locale: Locale
}

export default ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const { nodesSection, nodesBlogsEntries, nodesMetaData } = usePageContent()

    const blogEntriesCards = mappingNode<ImageWithTextCardModel>(nodesBlogsEntries, locale)

    const { title: sectionTitle } = getDataByLocale<SectionModel>(nodesSection, locale)

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section fadein={false}>
                <BlogBanner blogEntries={blogEntriesCards[0]} />
            </Section>
            <Section id="blog" title={sectionTitle} textAlign={TextAlign.CENTER}>
                <div className="entryCardsContainer">
                    {blogEntriesCards.map((blogCard) => (
                        <ImageWithTextCard key={blogCard.title} blogEntries={blogCard} />
                    ))}
                </div>
            </Section>
        </Page>
    )
}
