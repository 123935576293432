import React from 'react'

import './ImageWithTextCard.scss'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { clsx } from 'clsx'
import BookMarkButton from 'components/BookMarkButton'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import { IconSizeVariant, TextSize, TextVariant } from 'tuix-webcomponents'
import { getLanguageByLocale } from 'utils/language'
import useWindowDimensions, { MAX_MOBILE_WIDTH } from 'utils/window'

import { Locale } from '../../utils/enums'

type Url = {
    url: string
}
type File = {
    file: Url
}

type Raw = {
    raw: string
}

export interface ImageWithTextCardModel {
    title: string
    miniatureImage: File
    coverImage: File
    node_locale: Locale
    createdAt: string
    id: string
    articleText: Raw
    createdBy: string
    slug: string
    readingTime: number
    topic: string
}

type Props = {
    blogEntries: ImageWithTextCardModel
}

export default ({ blogEntries }: Props): JSX.Element => {
    const lang = getLanguageByLocale(blogEntries.node_locale)

    const prevText = documentToPlainTextString(JSON.parse(blogEntries.articleText.raw)).slice(
        0,
        200,
    )
    const { width } = useWindowDimensions()
    const { isDarkMode } = useDarkModeContext()
    const formatAndReadtime = dayjs(blogEntries.createdAt).format(
        `MMM D, YYYY | [${blogEntries.readingTime} min read]`,
    )

    return (
        <div className="imageWithTextCard_main">
            <div className={clsx('imageWithTextCard_container', isDarkMode && 'dark')}>
                <div className="imageWithTextCard_container__img">
                    <img
                        src={blogEntries.miniatureImage.file.url}
                        alt={`${blogEntries.title} miniature image`}
                    />
                </div>

                <div className={clsx('poligon', isDarkMode && 'dark')} />
                <Link
                    to={`/${lang}/blog/entry/${blogEntries.slug}`}
                    className={'imageWithText_link'}
                >
                    <div
                        className={clsx('imageWithTextCard_container__card', isDarkMode && 'dark')}
                    >
                        <div className="imageWithTextCard_container__card_resume">
                            <TuixTextComponent
                                size={TextSize.Body2}
                                color={TextVariant.MAIN}
                                dark={isDarkMode}
                                className="imageWithTextCard_container__card_resume_topic"
                            >
                                {blogEntries.topic}
                            </TuixTextComponent>
                            <TuixTextComponent
                                size={TextSize.Body2}
                                color={TextVariant.GREY}
                                dark={isDarkMode}
                                className="imageWithTextCard_container__card_resume_time"
                            >
                                {formatAndReadtime}
                            </TuixTextComponent>
                        </div>
                        <div className="imageWithTextCard_container__card_main">
                            <div className="title_container">
                                <TuixTextComponent size={TextSize.M} dark={isDarkMode}>
                                    {blogEntries.title}
                                </TuixTextComponent>
                            </div>
                            <div className="prevText_container">
                                <TuixTextComponent
                                    size={TextSize.Body1}
                                    dark={isDarkMode}
                                    color={TextVariant.GREY}
                                >
                                    {prevText}
                                </TuixTextComponent>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="button_container">
                    <BookMarkButton
                        size={
                            width <= MAX_MOBILE_WIDTH
                                ? IconSizeVariant.SMALL
                                : IconSizeVariant.MEDIUM
                        }
                        dark={isDarkMode}
                        className="save_button"
                    />
                </div>
            </div>
        </div>
    )
}
