import React from 'react'

import './BookMarkButton.scss'
import { clsx } from 'clsx'
import { TuixIconComponent } from 'components/TuixWebcomponents'
import { IconName, IconSizeVariant } from 'tuix-webcomponents'

import { getColor, getFill } from './BookMark.utils'

export interface Props {
    size: IconSizeVariant
    saved?: boolean
    dark?: boolean
    className?: string
    onSave?: (saved: boolean) => void
}

export default ({ saved = false, size, dark = false, className, onSave }: Props): JSX.Element => {
    const [isSaved, setIsSaved] = React.useState(saved)

    const handleOnClick = () => {
        setIsSaved(!isSaved)
        if (onSave) onSave(isSaved)
    }

    return (
        <button
            onClick={handleOnClick}
            className={clsx('bookmark-button', size, dark && 'dark', isSaved && 'saved', className)}
        >
            <TuixIconComponent
                name={IconName.BOOKMARK}
                size={size}
                color={getColor(isSaved, size, dark)}
                dark={dark}
                fill={getFill(isSaved, size, dark)}
            />
        </button>
    )
}
