import * as React from 'react'

import { Link } from 'gatsby'

import './BlogBanner.scss'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { ImageWithTextCardModel } from 'components/ImageWithTextCard/ImageWithTextCard'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import dayjs from 'dayjs'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { getLanguageByLocale } from 'utils/language'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'

export type BlogProps = {
    blogEntries: ImageWithTextCardModel
}

export default ({ blogEntries }: BlogProps): JSX.Element => {
    const date = dayjs(blogEntries.createdAt).format('MMM D, YYYY')
    const lang = getLanguageByLocale(blogEntries.node_locale)
    const { isDarkMode } = useDarkModeContext()
    const { responsiveWidth } = useDeviceWidth()

    return (
        <div>
            <Link className="blogBanner_link" to={`/${lang}/blog/entry/${blogEntries.slug}`}>
                <div
                    className="blogBanner_container"
                    style={{
                        backgroundImage: `url("${blogEntries.coverImage.file.url}?w=${responsiveWidth}&q=50&fm=webp")`,
                    }}
                >
                    <div className="blogBanner_textContainer">
                        <div className="blogBanner_textBox">
                            <TuixTextComponent
                                color={TextVariant.LIGHT}
                                size={TextSize.XL}
                                dark={isDarkMode}
                            >
                                {blogEntries.title}
                            </TuixTextComponent>

                            <TuixTextComponent
                                color={TextVariant.LIGHT}
                                size={TextSize.S}
                                dark={isDarkMode}
                            >
                                {date}
                            </TuixTextComponent>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
